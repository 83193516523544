<script setup lang="ts">
import type { mastodon } from '#types'
import type { MenuItem } from '~/types'

const props = defineProps<{
  status: mastodon.v1.Status
}>()
const emit = defineEmits(['require-reply'])

const { status, toggleFavourite, canReblog, toggleReblog, isLoading } = useStatusActions(props)

const statusRoute = getStatusRoute(props.status)
const shareMenuItems: MenuItem[] = [
  {
    label: 'reblog',
    command: canReblog ? toggleReblog : undefined,
  },
  {
    label: 'copy link',
    command: () => navigator.clipboard.writeText(`${window.location.origin}${statusRoute.href}`),
  },
]
</script>

<template>
  <div class="status-social-bar">
    <button
      :disabled="isLoading.favourited"
      class="compact"
      :class="{ active: status.favourited }"
      @click="toggleFavourite">
      <span class="icon-heart" />
      <span v-if="status.favouritesCount" class="counter">{{ status.favouritesCount }}</span>
    </button>
    <button class="compact" :class="{ active: status.repliesCount }" @click="emit('require-reply')">
      <span class="icon-comment" />
      <span v-if="status.repliesCount" class="counter">{{ status.repliesCount }}</span>
    </button>
    <UiPopupMenu :items="shareMenuItems" placement="top">
      <template #trigger-title>
        <span class="icon-share" />
        <span v-if="status.reblogsCount" class="counter">{{ status.reblogsCount }}</span>
      </template>
    </UiPopupMenu>
    <!-- <button
      v-if="canReblog"
      :disabled="isLoading.reblogged"
      class="compact"
      :class="{ active: status.reblogged }"
      @click="toggleReblog">
    </button> -->
  </div>
</template>

<style lang='scss'>
.status-social-bar {
  position: relative;
  display: flex;
  gap: var(--padding-mini);
  padding-inline: var(--padding-mini);

  .counter {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: var(--font-size-micro);
    opacity: 0.6;
  }
}
</style>
