<script setup lang="ts">
import type { mastodon } from '#types'
const props = defineProps<{
  size: string
  statusId: string
}>()
const emit = defineEmits(['update:size', 'sett'])
async function updateSize(gridSize?: mastodon.v1.PreviewCardGridSize | null) {
  emit('update:size', gridSize)
  try {
    const response = await $fetch(`/api/statuses/${props.statusId}`, { method: 'PUT', body: { gridSize } })
    emit('sett', (response as mastodon.v1.Status).card?.gridSize)
  } catch (e) {
    console.error(e)
  }
}
</script>

<template>
  <div class="status-grid-size-menu button-group">
    <button type="button" class="compact" @click="updateSize('normal')">
      <span class="normal" :class="{ active: !size || size === 'normal' }" />
    </button>
    <button type="button" class="compact" @click="updateSize('large')">
      <span class="big" :class="{ active: size === 'large' }" />
    </button>
    <button type="button" class="compact" @click="updateSize('vertical')">
      <span class="vertical" :class="{ active: size === 'vertical' }" />
    </button>
    <button type="button" class="compact" @click="updateSize('horizontal')">
      <span class="horizontal" :class="{ active: size === 'horizontal' }" />
    </button>
  </div>
</template>

<style lang='scss'>
.status-grid-size-menu {
  // --button-height: var(--base-size);
  display: flex;
  align-items: center;

  // width: calc(var(--button-height) * 3);
  button>span {
    $icon-big-side: 60%;
    $icon-small-side: $icon-big-side * 0.6;
    position: relative;
    border: 2px var(--color-text) solid;
    border-radius: 2px;

    &.active {
      border-color: black;

      &:before,
      &:after {
        border-color: black;
      }
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-style: dashed;
      border-color: var(--color-text);
    }

    &:before {
      width: 100%;
      height: 1px;
      border-bottom-width: 1px;
    }

    &:after {
      width: 1px;
      height: 100%;
      border-right-width: 1px;
    }

    &.vertical {
      width: $icon-small-side;
      height: $icon-big-side;

      &:before {
        width: 100%;
        height: 1px;
      }

      &:after {
        content: none;
      }
    }

    &.horizontal {
      width: $icon-big-side;
      height: $icon-small-side;

      &:after {
        width: 1px;
        height: 100%;
      }

      &:before {
        content: none;
      }
    }

    &.big {
      width: $icon-big-side;
      height: $icon-big-side;
    }

    &.normal {
      width: $icon-small-side;
      height: $icon-small-side;

      &:before,
      &:after {
        content: none;
      }
    }
  }
}</style>
