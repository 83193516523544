<script setup lang="ts">
const props = defineProps<{
  wave: number[]
}>()
const { wave } = toRefs(props)
const canvas = ref<HTMLCanvasElement>()

const { drawStaticWave } = useWaveformDrawing(canvas)
drawStaticWave(wave, (n: number) => n)
</script>

<template>
  <canvas ref="canvas" class="status-waveform" />
</template>

<style scoped>
canvas {
  height: var(--base-size);
  width: max(100%, var(--base-size) * 3);
}
</style>
