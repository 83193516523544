<script setup lang="ts">
import type { mastodon } from '#types'

const props = defineProps<{
  status: mastodon.v1.Status
  hover?: boolean
}>()

const el = ref<HTMLElement>()
const router = useRouter()
// const statusRoute = computed(() => getStatusRoute(props.status))
// const statusIdToOpen = useLocalStorage<string>('statusIdToOpen', '', { initOnMounted: true })

function onclick(evt: MouseEvent | KeyboardEvent) {
  const path = evt.composedPath() as HTMLElement[]
  const el = path.find(el => ['A', 'BUTTON', 'VIDEO'].includes(el.tagName?.toUpperCase()))
  const text = window.getSelection()?.toString()
  if (!el && !text) {
    go()
  }
}

function go() {
  // if (evt.metaKey || evt.ctrlKey) {
  //   window.open(statusRoute.value.href)
  // } else {
  cacheStatus(props.status)
  // router.value = props.status.id
  router.push({ path: useRoute().path, query: { s: props.status.id } })
  // router.push(statusRoute.value)
  // }
}
</script>

<template>
  <div
    :id="`status-${status.id}`"
    ref="el"
    tabindex="0"
    focus:outline-none
    focus-visible:ring="2 primary"
    aria-roledescription="status-card"
    :lang="status.language ?? undefined"
    @click="onclick"
    @keydown.enter="onclick">
    <slot />
  </div>
</template>
