import type { mastodon } from '#types'

type Action = 'reblogged' | 'favourited'
type CountField = 'reblogsCount' | 'favouritesCount'

export interface StatusActionsProps {
  status: mastodon.v1.Status
}

export function useStatusActions(props: StatusActionsProps) {
  const status = ref<mastodon.v1.Status>({ ...props.status })

  watch(() => props.status, (val) => {
    status.value = { ...val }
  }, { deep: true, immediate: true })

  // Use different states to let the user press different actions right after the other
  const isLoading = ref({
    reblogged: false,
    favourited: false,
    translation: false,
  })

  function toggleStatusAction(action: Action, fetchNewStatus: () => Promise<mastodon.v1.Status>, countField?: CountField) {
    if (!useAuth().checkLogin()) {
      return
    }

    const prevCount = countField ? status.value[countField] : undefined

    isLoading.value[action] = true
    const isCancel = status.value[action]
    fetchNewStatus().then((newStatus) => {
      // when the action is cancelled, the count is not updated highly likely (if they're the same)
      // issue of Mastodon API
      if (isCancel && countField && prevCount === newStatus[countField]) {
        newStatus[countField] -= 1
      }

      Object.assign(status.value, newStatus)
      cacheStatus(newStatus, true)
    }).finally(() => {
      isLoading.value[action] = false
    })
    // Optimistic update
    status.value[action] = !status.value[action]
    cacheStatus(status.value, true)
    if (countField) {
      status.value[countField] += status.value[action] ? 1 : -1
    }
  }

  const canReblog = computed(() => status.value.visibility !== 'direct' && (status.value.visibility !== 'private' || isSelfAccount(status.value.account)))

  const toggleReblog = () => toggleStatusAction(
    'reblogged',
    () => $fetch<mastodon.v1.Status>(`/api/statuses/${status.value.id}/${status.value.reblogged ? 'unreblog' : 'reblog'}`, { method: 'POST' }).then((res) => {
      if (status.value.reblogged) {
        // returns the original status
        return res.reblog!
      }
      return res
    }),
    'reblogsCount',
  )

  const toggleFavourite = () => toggleStatusAction(
    'favourited',
    () => $fetch<mastodon.v1.Status>(`/api/statuses/${status.value.id}/${status.value.favourited ? 'unfavourite' : 'favourite'}`, { method: 'POST' }),
    'favouritesCount',
  )

  return {
    status,
    isLoading,
    canReblog,
    toggleReblog,
    toggleFavourite,
  }
}
